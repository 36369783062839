export const environment = {
  production: true,
  branch: 'master',
  bugsnagApiKey: '121ea2a1a1400f165e54cb2ce7365249',
  api: 'https://dev-api.softbrik.com',
  allo: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  stak: 'https://v54j0czu71.execute-api.eu-west-1.amazonaws.com/prod',
  trak: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  file: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  contacts: 'https://27xny3xpy3.execute-api.eu-west-1.amazonaws.com/prod',
  trust: 'https://3hm96o38u0.execute-api.eu-west-1.amazonaws.com/prod',
  health: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  survey: 'https://17n3lrxohb.execute-api.eu-west-1.amazonaws.com/prod',
};
